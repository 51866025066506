import dayjs, { Dayjs } from 'dayjs';
import { FC, useState, createContext } from 'react';
type ContestContext = {
    contest: any;
    setContest: (data: Contest) => void;
};

export type Contest = {
    name: string;
    date: Dayjs;
    id: number; 
}

export const ContestContext = createContext<ContestContext>(
  {} as ContestContext
);

export const ContestProvider: FC = ({ children }) => {
  const [contest, setContest] = useState<Contest>({ name: 'UFC Throwdown', date: dayjs('06/05/23'), id: 145807738});

  return (
    <ContestContext.Provider
      value={{ contest, setContest }}
    >
      {children}
    </ContestContext.Provider>
  );
};
