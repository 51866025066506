import { useContext, useMemo, useRef, useState } from "react";

import {
  Badge,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  Popover,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import dayjs, { Dayjs } from "dayjs";
import {
  DateCalendar,
  DayCalendarSkeleton,
  PickersDay,
  PickersDayProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ContestContext } from "src/contexts/ContestContext";

const DatePickerButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const DatePickerBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const DatePickerText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const DatePickerLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const DatePickerDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderDatePicker() {
  const { contest, setContest } = useContext(ContestContext);
  const [highlightedDays, setHighlightedDays] = useState([3, 10, 17]);

  const pickedDate = {
    contestName: contest.name,
    contestDate: contest.date.format('MM/DD/YYYY'),
  };

  // TODO(cyr): Fetch this from the server and set to the last
  const allContestInformation = useMemo(() => {
    console.log('fetching');
    const fetched = [
      { name: 'UFC Throwdown1', date: dayjs('06/03/23'), id: 145807738},
      { name: 'UFC Throwdown2', date: dayjs('06/10/23'), id: 145807740},
      { name: 'UFC Throwdown3', date: dayjs('06/17/23'), id: 145807742},
    ];
    setContest(fetched[fetched.length - 1]);
    return fetched;
  }, []);
  // const initialDate = allContestInformation[allContestInformation.length - 1].date;

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  // Highlighted days are those with a contest available
  const handleMonthChange = (date: Dayjs) => {
    fetchHighlightedDays(date);
  };

  // Get a list of days that have contests.
  function getHighlightedDays(date: Dayjs) {
    let result = [];
    for (const contestInfo of allContestInformation) {
      const otherDate = contestInfo.date as Dayjs;
      if (
        date.month() == otherDate.month() &&
        date.year() == otherDate.year()
      ) {
        result = result.concat([otherDate.date()]);
      }
    }
    return result;
  }

  const fetchHighlightedDays = (date: Dayjs) => {
    setHighlightedDays(getHighlightedDays(date));
  };

  function CalendarDay(
    props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
  ) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const hasContestOnThisDay =
      !props.outsideCurrentMonth &&
      highlightedDays.indexOf(props.day.date()) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={hasContestOnThisDay ? "🤼‍♂️" : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          disabled={!hasContestOnThisDay}
        />
      </Badge>
    );
  }

  return (
    <>
      <DatePickerButton color="secondary" ref={ref} onClick={handleOpen}>
        <Hidden mdDown>
          <DatePickerText>
            <DatePickerLabel variant="body1">{pickedDate.contestName}</DatePickerLabel>
            <DatePickerDescription variant="body2">
              {pickedDate.contestDate}
            </DatePickerDescription>
          </DatePickerText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </DatePickerButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <DatePickerBox sx={{ minWidth: 210 }} display="flex">
          <DatePickerText>
            <DatePickerLabel variant="body1">{pickedDate.contestName}</DatePickerLabel>
            <DatePickerDescription variant="body2">
              {pickedDate.contestDate}
            </DatePickerDescription>
          </DatePickerText>
        </DatePickerBox>
        <Divider sx={{ mb: 0 }} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={contest.date ?? dayjs(new Date())}
            onMonthChange={handleMonthChange}
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
              day: CalendarDay,
            }}
            onChange={(date) => {
              for (let contestInfo of allContestInformation) {
                if (date.toString() == contestInfo.date.toString()) {
                  // Just picks the first date that matches. Eventually have a dropdown for contest on that date.
                  setContest(contestInfo);

                  // TODO: Update URL
                  break;
                }
              }
            }}
            slotProps={{
              day: {
                highlightedDays,
              } as any,
            }}
          />
        </LocalizationProvider>
        <Divider />
      </Popover>
    </>
  );
}

export default HeaderDatePicker;
