import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

const Builder = Loader(lazy(() => import('src/content/builder')));
const Field = Loader(lazy(() => import('src/content/field')));
const Outcomes = Loader(lazy(() => import('src/content/outcomes')));
const BuildResults = Loader(lazy(() => import('src/content/BuildResults')));

const SimulatedField = Loader(lazy(() => import('src/content/SimulatedField')));
const QueueSimulation = Loader(lazy(() => import('src/content/QueueSimulation')));
const SimulationResults = Loader(lazy(() => import('src/content/SimulationResults')));

const Status = Loader(lazy(() => import('src/content/status')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="overview" replace />
      },
      {
        path: 'overview',
        element: <Overview />
      },
      {
        path: 'builder',
        element: <Builder />
      },
      {
        path: 'field',
        element: <Field />
      },
      {
        path: 'outcomes',
        element: <Outcomes />
      },
      {
        path: 'build_results',
        element: <BuildResults />
      },
      {
        path: 'simulated_field',
        element: <SimulatedField />
      },
      {
        path: 'queue_simulation',
        element: <QueueSimulation />
      },
      {
        path: 'simulation_results',
        element: <SimulationResults />
      },
      {
        path: 'status',
        element: <Status/>
      },
    ]
  },
];

export default routes;
